export const modals = () => {
  function bindModal(modalSelector, openSelector, closeSelector) {
    const modal = document.querySelector(modalSelector);
    const openModalBtns = document.querySelectorAll(openSelector);
    const closeModalBtn = document.querySelector(closeSelector);
    const burger = document.querySelector(".menu");
    const overlay = document?.querySelector("[data-overlay]");
    const burgerBtn = document?.querySelector("[data-burger]");

    if (!modal || !closeModalBtn) return; // Проверка на наличие элементов

    // if (burger.classList.contains("menu--active")) {
    //   burger.classList.remove("menu--active");
    // }

    // Функция для открытия модального окна
    openModalBtns.forEach((item) => {
      item.onclick = function () {
        modal.style.display = "flex"; // Показать модальное окно

        if (burger.classList.contains("menu--active")) {
          burger.classList.remove("menu--active");
          burgerBtn.classList.toggle("burger--active");
          overlay.setAttribute("data-overlay", "false");
        }
      };
    });

    // Функция для закрытия модального окна
    closeModalBtn.onclick = function () {
      modal.style.display = "none"; // Скрыть модальное окно
    };

    // Закрытие модального окна при клике вне его
    window.onclick = function (event) {
      if (event.target == modal) {
        modal.style.display = "none"; // Скрыть модальное окно
      }
    };
  }

  if (document.querySelector(".modal-call-1")) {
    bindModal(
      ".modal-call-1",
      "[data-modal-call-trigger]",
      ".modal-call__close-1"
    );
  }

  if (document.querySelector(".modal-call-2")) {
    bindModal(
      ".modal-call-2",
      "[data-modal-call-trigger-2]",
      ".modal-call__close-2"
    );
  }
  if (document.querySelector(".modal-call-3")) {
    bindModal(
      ".modal-call-3",
      "[data-modal-call-trigger-3]",
      ".modal-call__close-3"
    );
  }
  if (document.querySelector(".modal-call-4")) {
    bindModal(
      ".modal-call-4",
      "[data-modal-call-trigger-4]",
      ".modal-call__close-4"
    );
  }
  if (document.querySelector(".modal-call-5")) {
    bindModal(
      ".modal-call-5",
      "[data-modal-call-trigger-5]",
      ".modal-call__close-5"
    );
  }
  if (document.querySelector(".modal-call-6")) {
    bindModal(
      ".modal-call-6",
      "[data-modal-call-trigger-6]",
      ".modal-call__close-6"
    );
  }
  if (document.querySelector(".modal-call-7")) {
    bindModal(
      ".modal-call-7",
      "[data-modal-call-trigger-7]",
      ".modal-call__close-7"
    );
  }
  if (document.querySelector(".modal-call-8")) {
    bindModal(
      ".modal-call-8",
      "[data-modal-call-trigger-8]",
      ".modal-call__close-8"
    );
  }
  if (document.querySelector(".modal-call-9")) {
    bindModal(
      ".modal-call-9",
      "[data-modal-call-trigger-9]",
      ".modal-call__close-9"
    );
  }
  if (document.querySelector(".modal-call-10")) {
    bindModal(
      ".modal-call-10",
      "[data-modal-call-trigger-10]",
      ".modal-call__close-10"
    );
  }
  if (document.querySelector(".modal-call-11")) {
    bindModal(
      ".modal-call-11",
      "[data-modal-call-trigger-11]",
      ".modal-call__close-11"
    );
  }

  // Пример для другого модального окна, если потребуется
  // bindModal(
  //   ".another-modal",
  //   "[data-another-modal-trigger]",
  //   ".another-modal__close"
  // );
};
