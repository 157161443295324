export const changeCity = () => {
  const cityWrapper = document.querySelectorAll("[data-hero-city]");
  const allCities = document.querySelectorAll("[data-contacts-list-item]");

  if (cityWrapper.length) {
    console.log("cityWrapper");
    allCities.forEach((city) => {
      city.addEventListener("click", () => {
        console.log("cityWrapperClick");
        const cityName = city.querySelector(".contacts-list__city");
        console.log(cityName.textContent);

        let string = cityName.textContent.replace(",", "");

        cityWrapper.forEach((item) => {
          item.textContent = string;
        });

        window.scrollTo({
          top: 0,
          behavior: "smooth", // Плавная прокрутка
        });
      });
    });
  }
};
