export const hideMenu = () => {
  // Получаем элемент
  const scrollElement = document.querySelector("[data-scroll-element]");

  if (scrollElement) {
    // Функция для проверки позиции прокрутки и ширины экрана
    function handleScroll() {
      // Получаем текущую позицию прокрутки и ширину экрана
      const scrollPosition = window.scrollY;
      const screenWidth = window.innerWidth;

      // Проверяем, больше ли позиция прокрутки 200px и меньше ли ширина экрана 992px
      if (scrollPosition > 200 && screenWidth < 992) {
        scrollElement.style.display = "flex"; // Показать элемент
      } else {
        scrollElement.style.display = "none"; // Скрыть элемент
      }
    }

    // Привязываем функцию к событию прокрутки
    window.addEventListener("scroll", handleScroll);

    // Привязываем также к изменению размера экрана, чтобы динамически скрывать/показывать при изменении ширины
    window.addEventListener("resize", handleScroll);
  }
};
