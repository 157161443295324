"use strict";

// Connecting vendors (plugins)
import "./_vendor";

// Functions
import {
  mobileCheck,
  accordion,
  tabs,
  video,
  changeCity,
  burger,
  modals,
  phoneMask,
  hideMenu,
  intlTelInput,
} from "./functions/";

window.addEventListener("DOMContentLoaded", () => {
  document.body.innerHTML = document.body.innerHTML.replace(/&nbsp;/g, " ");
  mobileCheck();
  tabs(
    ".result-block",
    ".result-block__item",
    ".result-content__item",
    "active"
  );
  tabs(".caces-block", ".caces-block__item", ".caces-content__item", "active");
  video();
  changeCity();
  burger();
  modals();
  phoneMask();
  hideMenu();
  intlTelInput();
  accordion(
    ".faq__list",
    "faq__item-title",
    ".faq__item",
    "faq__item--active",
    false
  );
});
