export const video = () => {
  const slides = document.querySelectorAll("[data-feedback-slide]");

  if (slides) {
    slides.forEach((slide) => {
      const trigger = slide.querySelector("[data-feedback-gif]");
      const play = slide.querySelector("[data-feedback-play]");
      const videoIframe = slide.querySelector("[data-feedback-video]");
      const videoSrc = videoIframe.getAttribute("src");

      play.addEventListener("click", () => {
        trigger.style.display = "none";
        play.style.display = "none";
        videoIframe.setAttribute("src", `${videoSrc}?autoplay=1`);
      });

      trigger.addEventListener("click", () => {
        trigger.style.display = "none";
        play.style.display = "none";
        videoIframe.setAttribute("src", `${videoSrc}?autoplay=1`);
      });
    });
  }
};
